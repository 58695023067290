import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
  asyncScheduler,
  Observable,
  ReplaySubject,
  scheduled,
  tap,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { AVAILABLE_LOCALES } from '@app/config/locales';
import { Settings } from 'luxon';
import { ToastService } from '@shared/services/toast.service';
import { UserPropertiesService } from '@auth/services/user-properties.service';
import { UserProperties } from '@models/enums/user-properties';

type Locale = string;

export const DEFAULT_LANGUAGE_KEY = 'i18nDefaultLanguage';

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  public static readonly currentLang$: ReplaySubject<string> =
    new ReplaySubject<string>(1);

  constructor(
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private toastService: ToastService,
    private userPropertiesService: UserPropertiesService,
  ) {
    LanguagesService.currentLang$.next(this.translateService.currentLang);
    translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      LanguagesService.currentLang$.next(event.lang);
    });
  }

  private getLocalStorageLocale(): Locale | null {
    return localStorage.getItem(DEFAULT_LANGUAGE_KEY);
  }

  private setLocalStorageLocale(locale: Locale): void {
    localStorage.setItem(DEFAULT_LANGUAGE_KEY, locale);
  }

  public getAvailableLocales(): Observable<Locale[]> {
    return scheduled([AVAILABLE_LOCALES], asyncScheduler);
  }

  public getDefaultLocale(locales: Locale[]): Locale {
    const localStorageLocale = this.getLocalStorageLocale();
    if (localStorageLocale && locales.includes(localStorageLocale)) {
      return localStorageLocale;
    } else {
      return locales[0];
    }
  }

  public setLanguage(locale: string, persist = false) {
    LanguagesService.currentLang$.next(locale);
    this.translateService.use(locale);
    this.dateAdapter.setLocale(locale);
    this.setLocalStorageLocale(locale);
    Settings.defaultLocale = locale;
    if (persist) {
      this.persistPreferLanguage(locale).subscribe({
        next: () =>
          this.toastService.success('Successfully update prefer language!'),
        error: () =>
          this.toastService.warning('Failed to update prefer language!'),
      });
    }
  }

  public persistPreferLanguage(locale: string): Observable<void> {
    return this.userPropertiesService.updateProperty(
      UserProperties.prefer_language,
      locale,
    );
  }

  public init() {
    this.getAvailableLocales()
      .pipe(
        tap((languages) => this.translateService.addLangs(languages)),
        map((languages) => this.getDefaultLocale(languages)),
      )
      .subscribe({
        next: (defaultLocale) => {
          this.translateService.setDefaultLang(defaultLocale);
          this.setLanguage(defaultLocale);
        },
      });
  }
}
