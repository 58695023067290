// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//const API_URL = 'http://localhost:8080';
const API_URL = 'https://api.chi-preprod-paris.ubicloud.net';
const KEYCLOAK_URL =
    'https://keycloak.api.ubicloud.net/auth/realms/chi-preprod/protocol/openid-connect';

export const environment = {
    production: true,
    hmr: false,
    backend: false,
    jwt: {
        realm: 'chi-preprod',
        clientId: 'chi-preprod-client',
        clientSecret: 'c3772f5c-746b-91ea-b4f1-d552754640fb',
        url: `${KEYCLOAK_URL}/token`,
        logout: `${KEYCLOAK_URL}/logout`,
    },
    api: {
        url: API_URL,
        downloadEndpoint: `${API_URL}/download`,
        graphqlUrl: `${API_URL}/graphql`,
        restApi: `${API_URL}/api`,
    },
    profile: 'preprod'
};
