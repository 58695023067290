import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { gql } from '@apollo/client/core';
import { Role } from '@models/data/role';
import { UserInfo } from '@models/data/user-info';
import { Apollo } from 'apollo-angular';
import { DateTime } from 'luxon';
import {
    catchError,
    EMPTY,
    interval,
    map,
    merge,
    Observable,
    of,
    shareReplay,
    Subject,
    switchMap,
    takeUntil,
    throwError
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadUserInfoService } from '@auth/services/load-user-info.service';

export const JWT = {
    access_token: 'JWT_ACCESS_TOKEN',
    refresh_token: 'JWT_REFRESH_TOKEN',
    expiration: 'JWT_EXPIRATION',
    refresh_expiration: 'JWT_REFRESH_EXPIRATION',
    grantType: {
        key: 'grant_type',
        password: 'password',
        refreshToken: 'refresh_token',
    },
    loginBody: {
        username: 'username',
        password: 'password',
        refreshToken: 'refresh_token',
    },
    logoutBody: {
        client_id: 'client_id',
        client_secret: 'client_secret',
        refreshToken: 'refresh_token',
    },
};

type AuthRequestResult = {
    access_token: string;
    expires_in: number;
    'not-before-policy': number;
    refresh_expires_in: number;
    refresh_token: string;
    scope: string;
    session_state: string;
    token_type: string;
};

export type UserInfoResult = {
    currentUserInfo: UserInfo;
};

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private static userInfo$: Observable<UserInfo> = EMPTY;
    private disconnectEvent$: Subject<void> = new Subject<void>();
    private loginEvent$: Subject<void> = new Subject<void>();
    private logoutEvent$: Subject<void> = new Subject<void>();

    constructor(
        private http: HttpClient,
        private apollo: Apollo,
        private router: Router,
        private loadUserInfoService: LoadUserInfoService,
    ) {
    }

    login(username: string, password: string): Observable<void> {
        const body: URLSearchParams = new URLSearchParams();
        body.set(JWT.grantType.key, JWT.grantType.password);
        body.set(JWT.loginBody.username, username);
        body.set(JWT.loginBody.password, password);
        let headers = new HttpHeaders();
        headers = headers.append(
            'Content-Type',
            'application/x-www-form-urlencoded',
        );
        return this.http
            .post(environment.jwt.url, body, {headers: headers})
            .pipe(
                map((result: any) => this.initSession(result)),
                map(_ => this.initUserObservable())
            );
    }

    logout() {
        const refreshToken = this.getRefreshToken();
        if (refreshToken) {
            const body: URLSearchParams = new URLSearchParams();
            body.set(JWT.logoutBody.client_id, environment.jwt.clientId);
            body.set(JWT.logoutBody.client_secret, environment.jwt.clientSecret);
            body.set(JWT.logoutBody.refreshToken, refreshToken);
            let headers = new HttpHeaders();
            headers = headers.append(
                'Content-Type',
                'application/x-www-form-urlencoded',
            );
            this.http.post(environment.jwt.logout, body, {headers}).subscribe({
                next: () => console.info('Finish Keycloak session'),
                error: () => console.warn('Failed to finish Keycloak session'),
            });
        }
        this.clearSession();
        this.disconnectEvent$.next();
        this.logoutEvent$.next();
        this.router.navigate(['login']);
    }

    initUserObservable() {
        const twentyMinutes: number = 1_000 * 60 * 20;
        AuthService.userInfo$ = merge(
            this.executeGetUserInfo(),
            interval(twentyMinutes).pipe(
                switchMap(() => this.executeGetUserInfo()),
            ),
        ).pipe(
            takeUntil(this.logoutEvent$),
            shareReplay(1),
            catchError((err) => {
                console.error(err)
                throw new Error('Failed to get user info');
            }),
        );
        AuthService.userInfo$.subscribe({
            next: () => console.debug('Refresh UserInfo'),
        });
    }

    refreshToken(): Observable<void> {
        const refreshToken = this.getRefreshToken();
        if (refreshToken === null)
            throw throwError(() => {
                const error: any = new Error(
                    'Refresh Token error, no refresh token available!',
                );
                error.timestamp = Date.now();
                return error;
            });
        const body: HttpParams = new HttpParams()
            .set(JWT.grantType.key, JWT.grantType.refreshToken)
            .set(JWT.loginBody.refreshToken, refreshToken);
        return this.http
            .post(environment.jwt.url, body)
            .pipe(map((result: any) => this.initSession(result)));
    }

    initSession(authRequestResult: AuthRequestResult): void {
        this.setAccessToken(authRequestResult.access_token);
        this.setRefreshToken(authRequestResult.refresh_token);
        this.setExpiration(authRequestResult.expires_in);
        this.setRefreshExpiration(authRequestResult.refresh_expires_in);
        this.loginEvent$.next();
        this.getUserInfo().subscribe({
            next: (userInfo) => this.loadUserInfoService.loadUserInfos(userInfo),
        });
    }

    static parseJWT(token: string): AuthRequestResult {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join(''),
        );

        return JSON.parse(jsonPayload);
    }

    getSessionId(): string | null {
        const accessToken = this.getAccessToken();
        if (accessToken) {
            return AuthService.parseJWT(accessToken).session_state;
        }
        return null;
    }

    clearSession(): void {
        this.apollo.client.cache.reset();
        localStorage.clear();
    }

    getRoles(): Observable<Role[]> {
        return this.getUserInfo().pipe(map((userInfo) => userInfo.roles));
    }

    hasRole(roleName: string) {
        return this.getRoles().pipe(
            map((userRoles) => userRoles.some((role) => role.name === roleName)),
        );
    }

    hasRoles(roles: string[]): Observable<boolean> {
        if (roles.length === 0) {
            return of(true);
        }
        return this.getRoles().pipe(
            map((userRoles) =>
                userRoles.some((role) =>
                    roles.some((roleName) => roleName === role.name),
                ),
            ),
        );
    }

    isAdmin(): Observable<boolean> {
        return this.getUserInfo().pipe(
            map((userInfo) =>
                userInfo.roles.some((role) => 'Administrator' === role.name),
            ),
        );
    }

    private executeGetUserInfo(): Observable<UserInfo> {
        return this.apollo
            .query<UserInfoResult>({
                query: gql`
          query UserInfo {
            currentUserInfo {
              userId
              userName
              roles {
                id
                name
                permissions
              }
              properties
            }
          }
        `,
                fetchPolicy: 'cache-first',
            })
            .pipe(map((result) => result?.data?.currentUserInfo));
    }

    getUserInfo(): Observable<UserInfo> {
        if (AuthService.userInfo$ == EMPTY) this.initUserObservable();
        return AuthService.userInfo$;
    }

    isLogged(): boolean {
        const jwtExpiration = this.getRefreshExpiration();
        return jwtExpiration !== null && new Date(jwtExpiration) > new Date();
    }

    isLogOut(): boolean {
        const jwtExpiration = this.getRefreshExpiration();
        return jwtExpiration === null || new Date(jwtExpiration) <= new Date();
    }

    getKeycloakBasicAuth() {
        const clientId: string = environment.jwt.clientId;
        const clientSecret: string = environment.jwt.clientSecret;
        return window.btoa(`${clientId}:${clientSecret}`);
    }

    getAccessToken(): string | null {
        return this.isLogged() ? localStorage.getItem(JWT.access_token) : null;
    }

    getRefreshToken(): string | null {
        return localStorage.getItem(JWT.refresh_token);
    }

    getExpiration(): string | null {
        return localStorage.getItem(JWT.expiration);
    }

    getRefreshExpiration(): string | null {
        return localStorage.getItem(JWT.refresh_expiration);
    }

    private setAccessToken(accessToken: string): void {
        return localStorage.setItem(JWT.access_token, accessToken);
    }

    private setRefreshToken(refreshToken: string): void {
        return localStorage.setItem(JWT.refresh_token, refreshToken);
    }

    private setExpiration(expirationInSeconds: number): void {
        let expirationDate: DateTime = DateTime.now();
        expirationDate = expirationDate.plus({second: expirationInSeconds});
        const expirationDateString = expirationDate.toISO();
        if (expirationDateString) {
            localStorage.setItem(JWT.expiration, expirationDateString);
        }
    }

    private setRefreshExpiration(expirationInSeconds: number): void {
        let expirationDate: DateTime = DateTime.now();
        expirationDate = expirationDate.plus({second: expirationInSeconds});
        const expirationDateString = expirationDate.toISO();
        if (expirationDateString) {
            localStorage.setItem(JWT.refresh_expiration, expirationDateString);
        }
    }

    hasPermissions(objectsPermissionsNeeded: string[]): Observable<boolean> {
        return this.getRoles().pipe(
            map(roles => objectsPermissionsNeeded.every(objectPermissionNeeded => this.hasRightsOnObject(roles, objectPermissionNeeded))),
        );
    }

    hasPermission(objectPermissionNeeded: string): Observable<boolean> {
        return this.hasPermissions([objectPermissionNeeded]);
    }

    hasRightsOnObject(userRoles: Role[], objectName: string): boolean {
        return userRoles.some((role) => {
            if (!Object.keys(role.permissions).includes(objectName)) {
                return false;
            }
            const permission = role.permissions[objectName];
            return Object.entries(permission.profileOperations)
                .map(([_, operation]) => operation.values)
                .flat()
                .includes('READ');
        });
    }
}
